import $ from 'jquery'
import slick from './slick.min'

$(document).ready(function(){
  $('.our-partners-section-content').slick({
    slidesToShow: 6 ,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 4,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        }
      },
    ]
  })
})