import $ from 'jquery';

(function () {
  const iframe = $('#frame')
  iframe.on('load', function() {
    // iframe content
    const iframeContents = $(this).contents();

    // find #message in iframe contents
    const element = iframeContents.find('body');

    console.log(element);

    // get element value
    const message = element.html();

    // display element value in view
    $('#iframe-content').html(message);
  })
  const openModalButtons = $('[data-modal]')
  const backDrop = $('.backdrop')
  const modalCloseIcon = $('.clsbtn')
  const modalSendButton = $('#sendModalData')

  openModalButtons.each(function(){
    $(this).click(()=>{
      backDrop.removeClass('is-hidden')
      $('body').addClass('modal-open')
    })
  })

  backDrop.click(function(e){
    e.stopPropagation()
    if(e.target === e.currentTarget){
      $(this).addClass('is-hidden')
      $('body').removeClass('modal-open')
    }
  })

  modalCloseIcon.click(function(e){
    e.stopPropagation()
    backDrop.addClass('is-hidden')
    $('body').removeClass('modal-open')
  })
})()