import $ from 'jquery';

(function () {
  const menuButton = $('.menu-thumb')
  const navMenu = $('.nav-list')
  $(document).click(()=>{
    navMenu.addClass('is-hidden')
  })

  menuButton.click((e)=>{
    e.stopPropagation()
    navMenu.toggleClass('is-hidden')
  })
})()